<template>
    <div class="grid grid-cols-12 gap-4">
        <div class="col-span-3 bg-white rounded-lg shadow-lg">
            <div class="flex border-b border-gray-100 items-center pb-5 p-5">
                <div class="ml-3">
                    <h3 class="text-gray-400">{{ info.name }}</h3>
                    <p class="text-gray-300">帳號狀態: {{ info.status === 0 ? "使用中" : "停用" }}</p>
                </div>
            </div>
            <div class="border-b border-gray-100 p-5">
                <ul class="list-none">
                    <li
                        v-for="(item, index) in menus['first']"
                        :key="index"
                        class="cursor-pointer pl-5 py-3"
                        :class="item.value === $route.name ? 'bg-red-500 bg-opacity-20 rounded-lg' : ''"
                        @click="changeRoute(item.value)">
                        {{ item.label }}
                    </li>
                </ul>
            </div>
            <div class="p-5">
                <ul class="list-none">
                    <li
                        v-for="(item, index) in menus['second']"
                        :key="index"
                        class="cursor-pointer pl-5 py-3"
                        :class="item.value === $route.name ? 'bg-red-500 bg-opacity-20 rounded-lg' : ''"
                        @click="changeRoute(item.value)">
                        {{ item.label }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-span-9 rounded-lg relative" :class="{ 'bg-white p-5': backgroundWhite }">
            <router-view v-if="info.name" @update="getBrokerData" @updateBreadCrumb="updateBreadCrumb"></router-view>
        </div>
    </div>
</template>

<script>
export default {
    name: "EditIntroducer",
    components: {},
    computed: {
        backgroundWhite() {
            const val = this.$route.name;
            if (val === "edit_introducer_providerList" || val === "edit_introducer_paymentInfo") {
                return false;
            }
            return true;
        },
    },
    data() {
        return {
            id: null,
            menus: {
                first: [
                    { label: "個人資料", value: "edit_introducer_profile" },
                    { label: "撥款帳戶", value: "edit_introducer_account" },
                ],
                second: [
                    { label: "服務商名單", value: "edit_introducer_providerList" },
                    { label: "款項資訊", value: "edit_introducer_paymentInfo" },
                ],
            },
            info: {},
        };
    },
    methods: {
        changeRoute(val) {
            this.$router.push({ name: val });
        },
        getBrokerData() {
            this.$api.GetSingleBrokerApi(this.id).then((res) => {
                this.info = { ...res.data };
            });
        },
        updateBreadCrumb(val) {
            this.$store.commit("breadcrumbStore/setCrumbList", [
                {
                    meta: {
                        text: "用戶管理",
                    },
                },
                {
                    name: "introducer_list",
                    meta: {
                        text: "介紹人管理",
                    },
                    path: "#/user_list/introducer_list",
                },
                {
                    meta: {
                        text: this.info.name,
                    },
                },
                {
                    name: this.$route.name,
                    meta: {
                        text: val,
                    },
                },
            ]);
        },
    },
    async mounted() {
        this.id = this.$route.params.id;
        await this.getBrokerData();
    },
};
</script>

<style></style>
